import { ready } from '~/utils/common';
import Header from '~/components/Header';
import FlexTabs from '~/components/FlexTabs';
import LazyImages from '~/components/LazyImages';

const init = () => {
  Header.scrollState();
  FlexTabs();
  LazyImages();
};

ready(init);
