class Tabs {
  constructor(container) {
    this.container = container;
  }

  init() {
    const links = Array.from(this.container.querySelectorAll('.flex-tabs__link'));
    if (links.length === 0) {
      return;
    }

    links.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        this.selectTab(link);
      });
    });
  }

  selectTab(link) {
    const pane = this.container.querySelector(link.hash);

    const activeLink = this.container.querySelector('.flex-tabs__link--active');
    const activePane = this.container.querySelector('.flex-tabs__pane--active');

    if (activeLink) {
      activeLink.classList.remove('flex-tabs__link--active');
    }

    if (activePane) {
      activePane.classList.remove('flex-tabs__pane--active');
    }

    link.classList.add('flex-tabs__link--active');
    pane.classList.add('flex-tabs__pane--active');
  }
}

function init() {
  document.querySelectorAll('[data-flex-tabs]').forEach(tab => {
    new Tabs(tab).init();
  });
}

export default init;
