import { debounce } from 'lodash';

const SCROLL_TOP_TO_CHANGE_HEADER = 55;
var header;

const checkState = function() {
  const scrollPos = window.pageYOffset;
  const rect = header.getBoundingClientRect();

  if (scrollPos > SCROLL_TOP_TO_CHANGE_HEADER && rect.top === 0) {
    header.classList.add("header--scrolled");
  } else {
    header.classList.remove("header--scrolled");
  }
};

const scrollState = function() {
  header = document.getElementById("header");

  window.addEventListener("scroll", debounce(checkState));
  checkState();
};

// Change header modifier class when scroll condition is met
export default {
  scrollState: scrollState
}
